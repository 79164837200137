body {
  font-family: 'Lato';
}
a {
  color: #007db8;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.card-block {
  padding: 20px;
}
.padding-left-10 {
  padding-left: 10px;
}
// TODO: put this Mui overrides into the class when className prop will be ready for Tabs component
.MuiTabs-root {
  margin-top: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;
}

.breadcrumbs {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin-top: 70px;
  padding: 18px 35px 20px;
  
  border-bottom: 1px solid #E0E0E0;;

  a {
    font-size: 15px;
  }
}

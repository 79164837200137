.abaLoader {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 160px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;

  .abaLoaderWrapper {
    width: 40px;
    height: 40px;
    animation: div-spin 1.4s linear infinite;
  
    .abaLoaderSvg {
      color: #007db8;
  
      .abaLoaderCircle {
        stroke-dasharray: 80px, 200px;
        stroke-dashoffset: 0px;
        animation: loader-spin 1.4s ease-in-out infinite;
      }
    }
  }
}


@keyframes loader-spin {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
  }
  100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
  }
}

@keyframes div-spin {
  0% {
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(360deg);
  }
}